import axios from 'axios';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { id } from 'date-fns/locale';
import { createContext, useState } from 'react';

const BASE_URL = `https://apaansi.pn-banyumas.go.id/backend/api/`; //taruh apimu disini

export const Context = createContext();

export const ClockMurni = () =>{
  let time  = new Date().toLocaleTimeString();

  const [ctime,setTime] = useState(time);
  const UpdateTime=()=>{
    time =  new Date().toLocaleTimeString()
    setTime(time)
  };
  setInterval(UpdateTime);
  return <>{ctime}</>;
};

export const alertError = (text) => {
  Swal.fire({
    icon: 'error',
    title: text,
    confirmButtonText: "Refresh",
    text: `Refresh halaman untuk melanjutkan ..`,
    showCancelButton: false,
    confirmButtonColor: "#d33",
  }).then((result) => {
    if (result.isConfirmed) {
      //window.location.reload();
    }
  });
  setTimeout(()=>{
      //window.location.reload();
  }, 10000);
};

export const alertNotif = (judul, text, icon) =>{
  Swal.fire(judul, text, icon);
};

export const alertQuestion = (judul, text, textTrue, textFalse, onTrue, onFalse) =>{
  Swal.fire({
    title: judul,
    text: text,
    showCancelButton: false,
    showDenyButton: true,
    confirmButtonText: textTrue,
    denyButtonText: textFalse,
  }).then((result) => {
    if (result.isConfirmed) {
      if(typeof onTrue !== 'undefined' && onTrue !== null){
        onTrue();
      }
    } else if (result.isDenied) {
      if(typeof onFalse !== 'undefined' && onFalse !== null){
        onFalse();
      }
    }
  });  
}

export const pidana_hari_ini = async () => {
  try {
    const response = await axios.post(`${BASE_URL}data_sidang_pidana_hari_ini`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const perdata_hari_ini = async () => {
  try {
    const response = await axios.post(`${BASE_URL}data_sidang_perdata_hari_ini`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const ambil_data = async (query) => {
  try {
    const response = await axios.post(`${BASE_URL}ambil_data`,{ query });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const tambah_data = async (table, data) => {
  try {
    const response = await axios.post(`${BASE_URL}tambah_data`, {table, data}, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const edit_data = async(table, id, data) => {
  try{
    const response = await axios.post(`${BASE_URL}edit_data`, {table, id , data},{
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  }catch (error){
    console.error('Error update data:', error);
    throw error;
  }
};

export const login = async(password, data) =>{
  try{
    const response = await axios.post(`${BASE_URL}login`, { password, data }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  }catch (error){
    console.error('Error login:', error);
    throw error;
  }
}

export const formattedDate = (date) =>{
    return format(date, 'yyyy-MM-dd');
};

export const formattedHariIni = (date) =>{
    return format(date, 'd MMMM yyyy', {locale: id});
};

export const formattedHariIniJam = (date) =>{
  return format(date, 'yyyy-MM-dd HH:mm:ss', {locale: id});
};

export const query = (tgl) =>{
  return `
      SELECT
          vdp.perkara_id,
          vdp.nomor_perkara,
          vdp.agenda,
          vdp.pihak_dipublikasikan,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak1_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak1_tdk_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak2_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak2_tdk_hadir,
          vdp.majelis_hakim_text AS hakim,
          vdp.panitera_pengganti_text AS panitera_pengganti,
          vdp.tglSidang
      FROM
          view_data_perkara vdp
      LEFT JOIN
          view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
      LEFT JOIN
          data_kehadiran dk ON vdp.perkara_id = dk.perkara_id AND vpdh.nama LIKE CONCAT('%', dk.nama, '%') AND dk.tglSidang = '${tgl}'
      WHERE
          (
              nomor_perkara LIKE '%Pid.B%' 
              OR 
              nomor_perkara LIKE '%Pid.Sus%' 
              OR 
              nomor_perkara LIKE '%Pid.Sus-Anak%' 
              OR 
              nomor_perkara LIKE '%Pdt.P%' 
              OR 
              nomor_perkara LIKE '%Pdt.G%'
              OR
              nomor_perkara LIKE '%Pdt.G.S%'
          )
          AND
          vdp.tglSidang = '${tgl}'
      GROUP BY
          vdp.perkara_id
      ORDER BY 
          vdp.nomor_perkara ASC;
      `;
}

export const query_dua = (tgl) =>{
  return `
      SELECT
          vdp.perkara_id,
          vdp.nomor_perkara,
          vdp.agenda,
          vdp.pihak_dipublikasikan,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak1_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak1_tdk_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak2_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak2_tdk_hadir,
          vdp.majelis_hakim_text AS hakim,
          vdp.tglSidang,
          vpdh.jenis_perkara_nama
      FROM
          view_data_perkara vdp
      LEFT JOIN
          view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
      LEFT JOIN
          data_kehadiran dk ON vdp.perkara_id = dk.perkara_id AND vpdh.nama LIKE CONCAT('%', dk.nama, '%') AND dk.tglSidang = '${tgl}'
      WHERE
          (
              nomor_perkara LIKE '%Pid.B%' 
              OR 
              nomor_perkara LIKE '%Pid.Sus%' 
              OR 
              nomor_perkara LIKE '%Pid.Sus-Anak%' 
              OR 
              nomor_perkara LIKE '%Pdt.P%' 
              OR 
              nomor_perkara LIKE '%Pdt.G%'
              OR
              nomor_perkara LIKE '%Pdt.G.S%'
          )
          AND
          vdp.tglSidang = '${tgl}'
      GROUP BY
          vdp.perkara_id
      ORDER BY 
          vdp.nomor_perkara ASC;
      `;
}

export const query_tiga = (id) =>{
  return `
      SELECT
          *
      FROM
          view_data_perkara 
      WHERE
          perkara_id = '${id}'
      ORDER BY 
          tglSidang DESC;
      `;
}

export const query_empat = (id, tgl) =>{
  return `
      SELECT
          vdp.perkara_id,
          vdp.nomor_perkara,
          vdp.agenda,
          vdp.pihak_dipublikasikan,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak1_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                  WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak1_tdk_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak2_hadir,
          GROUP_CONCAT(
              DISTINCT CASE
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                  WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                  ELSE NULL
              END
              SEPARATOR '|'
          ) AS pihak2_tdk_hadir,
          vdp.majelis_hakim_text AS hakim,
          vdp.tglSidang,
          vpdh.jenis_perkara_nama,
          1 as hasil_detail
      FROM
          view_data_perkara vdp
      LEFT JOIN
          view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
      LEFT JOIN
          data_kehadiran dk ON vdp.perkara_id = dk.perkara_id AND vpdh.nama LIKE CONCAT('%', dk.nama, '%') AND dk.tglSidang = '${tgl}'
      WHERE
          vdp.perkara_id = '${id}'
      GROUP BY
          vdp.id
      ORDER BY
          vdp.tglSidang DESC
      LIMIT 1;
      `;
}

export const query_lima = (tgl) =>{
  return `
    SELECT
        vdp.nomor_perkara AS 'Nomor Perkara',
        vdp.agenda AS 'Agenda',
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS 'Pihak Pertama',
        GROUP_CONCAT(
            DISTINCT CASE
              WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') THEN vpdh.nama
              ELSE NULL
            END
            SEPARATOR '|'
        ) AS 'Pihak Kedua',
        vdp.majelis_hakim_text AS 'Majelis Hakim',
        vdp.panitera_pengganti_text AS 'Panitera Pengganti'
    FROM
        view_data_perkara vdp
    LEFT JOIN
        view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
    WHERE
        (
            nomor_perkara LIKE '%Pid.B%' 
            OR 
            nomor_perkara LIKE '%Pid.Sus%' 
            OR 
            nomor_perkara LIKE '%Pid.Sus-Anak%' 
            OR 
            nomor_perkara LIKE '%Pdt.P%' 
            OR 
            nomor_perkara LIKE '%Pdt.G%'
            OR
            nomor_perkara LIKE '%Pdt.G.S%'
        )
        AND
        vdp.tglSidang = '${tgl}'
    GROUP BY
        vdp.perkara_id
    ORDER BY 
        vdp.nomor_perkara ASC;
    `;
};

export const cari_data = async (value) => {
  try {
    const response = await axios.post(`${BASE_URL}cari`,{ value });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const redirectToLocalURL = () => {
  Swal.fire({
      title: 'Gagal menyambung ke WebSocket lokal',
      text: 'Klik advance dan procced to 192.168.3.7',
      icon: 'warning',
      confirmButtonText: 'OK',
      showCancelButton: true,
  }).then((result) => {
      if (result.isConfirmed) {
          window.location.href = "https://192.168.3.7:83"; // Redirect ke URL lokal setelah klik OK
      }
  });
};

export const truncatePihak = (nama) =>{
  if (nama !== null) {
    let p = nama.split(' | ');
  
    let names = p.map(nameWithTitle => {
      let name = nameWithTitle.split(/Alias|alias|als|,/i)[0].trim();
      if (name.length > 18) {
        name = name.substring(0, 18).trim();
      }
      return name;
    });
  
    let truncatedNames = names.slice(0, 2).join(' | ');
  
    if (p.length > 2) {
      nama = `${truncatedNames} dkk.`;
    } else {
      nama = `${truncatedNames}`;
    }

    return nama.toLowerCase();
  }
};

export const truncateRuangan = (ruangan) =>{
  let ruang = '';
  if(ruangan !== null){
    switch(ruangan){
      case 'RUANG SIDANG I':
        ruang = 'RUANG SIDANG 1'
        break;
      case 'RUANG SIDANG II':
        ruang = 'RUANG SIDANG 2'
        break;
      default:
        ruang = ruangan;
    }
  }
  return ruang.toLocaleLowerCase();
}

export const prepTeks = (nomor_perkara, pihak_dipublikasikan, pihak1, pihak2, ruang_sidang) =>{
  let jenis = "";
  let teks = "";

  if(nomor_perkara.includes('Pdt.P')){
      jenis = "permohonan";
      teks = `panggilan, untuk pihak atas nama ${truncatePihak(pihak1)} perkara permohonan nomor ${truncateNomor(nomor_perkara)}, harap menuju ${truncateRuangan(ruang_sidang)}, sidang akan segera dimulai.`;
  }else if(nomor_perkara.includes('Pdt')){
      jenis = "perdata";
      if (pihak_dipublikasikan === "Y") {
          teks = `panggilan, penggugat ${truncatePihak(pihak1)} dan tergugat ${truncatePihak(pihak2)} perkara nomor ${truncateNomor(nomor_perkara)} ${jenis}, harap menuju ${truncateRuangan(ruang_sidang)}, sidang akan segera dimulai.`;
      } else {
          jenis = "perdata gugatan";
          teks = `panggilan, untuk pihak penggugat dan pihak tergugat perkara ${jenis} nomor ${truncateNomor(nomor_perkara)}, harap menuju ${truncateRuangan(ruang_sidang)}, sidang akan segera dimulai.`;
      }
  }else if(nomor_perkara.includes('Pid')){
      if (pihak_dipublikasikan === "Y") {
          jenis = "pidana";
          teks = `panggilan, atas nama terdakwa ${truncatePihak(pihak2)}, perkara nomor ${truncateNomor(nomor_perkara)}, harap menuju ${truncateRuangan(ruang_sidang)}, sidang akan segera dimulai.`;
      } else {
          jenis = "pidana anak";
          teks = `panggilan, untuk penuntut umum ${truncatePihak(pihak1)}, dan pihak perkara nomor ${truncateNomor(nomor_perkara)} ${jenis}, harap menuju ${truncateRuangan(ruang_sidang)}, sidang akan segera dimulai.`;
      }
  }

  return teks;
}

export const truncateNomor = (nomor) =>{
  if (nomor !== null) {
    let p = nomor.split('/')[0];
    return p;
  }
};

export const cekWS = (stat) =>{
    switch(stat){
        case 0:
            alertNotif('Status', 'Sedang menghubungkan dengan server ..', 'question');
        break;
        case 1:
            alertNotif('Status', 'Terhubung dengan server!', 'success');
        break;
        case 2:
            alertNotif('Status', 'Tidak terhubung dengan server!', 'error');
        break;
        case 3:
            alertNotif('Status', 'Tidak terhubung dengan server!', 'error');
        break;
        default:
            alertNotif('Status', 'Tidak terhubung dengan server!', 'error');
    }
};

export const truncateName = (name) => {
  const maxLength = 20;
  if (name.length > maxLength) {
      return name.substring(0, maxLength) + '...';
  } else {
      return name;
  }
};

export const query_sidang = (tgl, user, stat) =>{
  if(stat === 0){
    return`
      SELECT
        vdp.id,
        vdp.perkara_id,
        vdp.nomor_perkara,
        vdp.agenda,
        vdp.pihak_dipublikasikan,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS pihak1_hadir,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS pihak1_tdk_hadir,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS pihak2_hadir,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS pihak2_tdk_hadir,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum', 'Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR ' | '
        ) AS semua_pihak,
        vdp.majelis_hakim_text AS hakim,
        vdp.panitera_pengganti_text AS panitera_pengganti,
        vdp.tglSidang,
        COALESCE
          (ds.status, 0) AS status,
        COALESCE
          (ds.ket, 'Belum mulai') AS ket
      FROM
        view_data_perkara vdp
      LEFT JOIN
        view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
      LEFT JOIN
        data_kehadiran dk ON vdp.perkara_id = dk.perkara_id AND vpdh.nama LIKE CONCAT('%', dk.nama, '%') AND dk.tglSidang = '${tgl}'
      LEFT JOIN
        data_status ds ON vdp.id = ds.id
      WHERE
        (
          nomor_perkara LIKE '%Pid.B%' 
          OR 
          nomor_perkara LIKE '%Pid.Sus%' 
          OR 
          nomor_perkara LIKE '%Pid.Sus-Anak%' 
          OR 
          nomor_perkara LIKE '%Pdt.P%' 
          OR 
          nomor_perkara LIKE '%Pdt.G%'
          OR
          nomor_perkara LIKE '%Pdt.G.S%'
        )
        AND
        (
          majelis_hakim_text LIKE '%${user}%'
          OR
          panitera_pengganti_text LIKE '%${user}%'
        )
        AND
          (
            vdp.tglSidang = '${tgl}'
          )
        AND
          (
            ds.id IS NULL OR ds.status = ${stat}
          )
      GROUP BY
          vdp.perkara_id
      ORDER BY 
          vdp.nomor_perkara ASC`;
  }else{
    return`
      SELECT
        vdp.id,
        vdp.perkara_id,
        vdp.nomor_perkara,
        vdp.agenda,
        vdp.pihak_dipublikasikan,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS pihak1_hadir,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS pihak1_tdk_hadir,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS pihak2_hadir,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND (dk.kehadiran = 'T' OR dk.kehadiran IS NULL) THEN vpdh.nama
                WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' AND dk.tglSidang != '${tgl}' THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR '|'
        ) AS pihak2_tdk_hadir,
        GROUP_CONCAT(
            DISTINCT CASE
                WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum', 'Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') THEN vpdh.nama
                ELSE NULL
            END
            SEPARATOR ' | '
        ) AS semua_pihak,
        vdp.majelis_hakim_text AS hakim,
        vdp.panitera_pengganti_text AS panitera_pengganti,
        vdp.tglSidang,
        COALESCE
          (ds.status, 0) AS status,
        COALESCE
          (ds.ket, 'Belum mulai') AS ket
      FROM
        view_data_perkara vdp
      LEFT JOIN
        view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
      LEFT JOIN
        data_kehadiran dk ON vdp.perkara_id = dk.perkara_id AND vpdh.nama LIKE CONCAT('%', dk.nama, '%') AND dk.tglSidang = '${tgl}'
      LEFT JOIN
        data_status ds ON vdp.id = ds.id
      WHERE
        (
          nomor_perkara LIKE '%Pid.B%' 
          OR 
          nomor_perkara LIKE '%Pid.Sus%' 
          OR 
          nomor_perkara LIKE '%Pid.Sus-Anak%' 
          OR 
          nomor_perkara LIKE '%Pdt.P%' 
          OR 
          nomor_perkara LIKE '%Pdt.G%'
          OR
          nomor_perkara LIKE '%Pdt.G.S%'
        )
        AND
        (
          majelis_hakim_text LIKE '%${user}%'
          OR
          panitera_pengganti_text LIKE '%${user}%'
        )
        AND
          (
            vdp.tglSidang = '${tgl}'
          )
        AND
          (
            ds.status = ${stat}
          )
      GROUP BY
          vdp.perkara_id
      ORDER BY 
          vdp.nomor_perkara ASC`;
  }
};

export const query_rank = (tgl)=>{
  return`
  SELECT 
    vdp.id,
    vdp.perkara_id,
    vdp.nomor_perkara,
    vdp.pihak_dipublikasikan,
    GROUP_CONCAT(
        DISTINCT CASE
            WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') THEN vpdh.nama
            ELSE NULL
        END
        SEPARATOR ' | '
    ) AS pihak1,
    GROUP_CONCAT(
        DISTINCT CASE
          WHEN status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') THEN vpdh.nama
          ELSE NULL
        END
        SEPARATOR ' | '
    ) AS pihak2,
    vdp.ruangan,
    CASE 
        WHEN vdp.nomor_perkara LIKE '%Pid%' OR vdp.nomor_perkara LIKE '%Pid.Sus%' OR vdp.nomor_perkara LIKE '%Pid.Sus-Anak%' THEN 'Pidana'
        WHEN vdp.nomor_perkara LIKE '%Pdt.P%' THEN 'Permohonan'
        WHEN vdp.nomor_perkara LIKE '%Pdt.G%' OR vdp.nomor_perkara LIKE '%Pdt.Bth%' OR vdp.nomor_perkara LIKE '%Pdt.G.S%' THEN 'Perdata'
        ELSE 'Perkara'
    END AS jenis,
    COALESCE(ds.status, 0) AS status,
    COALESCE(ds.ket, 'Belum mulai') AS ket
  FROM 
    view_data_perkara vdp
  LEFT JOIN
    view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
  LEFT JOIN 
    data_status ds ON vdp.id = ds.id
  WHERE
    vdp.tglSidang = '${tgl}'
  GROUP BY
    vdp.perkara_id
  ORDER BY
    ds.status ASC;
  `;
};
