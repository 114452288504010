import React, { useEffect, useState, useRef } from "react";
import { useSpeech, useQueue } from "react-text-to-speech";
import { ambil_data, redirectToLocalURL, formattedDate, truncatePihak, truncateNomor } from "./services";

const prepTeks = (nomor_perkara, pihak_dipublikasikan, pihak1, pihak2, ruang_sidang) =>{
    let jenis = "";
    let teks = "";

    if(nomor_perkara.includes('Pdt.P')){
        jenis = "permohonan";
        teks = `panggilan, untuk pihak atas nama ${truncatePihak(pihak1)} perkara permohonan nomor ${truncateNomor(nomor_perkara)}, harap menuju ${ruang_sidang}, sidang akan segera dimulai.`;
    }else if(nomor_perkara.includes('Pdt')){
        jenis = "perdata";
        if (pihak_dipublikasikan === "Y") {
            teks = `panggilan, penggugat ${truncatePihak(pihak1)} dan tergugat ${truncatePihak(pihak2)} perkara nomor ${truncateNomor(nomor_perkara)} ${jenis}, harap menuju ${ruang_sidang}, sidang akan segera dimulai.`;
        } else {
            jenis = "perdata gugatan";
            teks = `panggilan, untuk pihak penggugat dan pihak tergugat perkara ${jenis} nomor ${truncateNomor(nomor_perkara)}, harap menuju ${ruang_sidang}, sidang akan segera dimulai.`;
        }
    }else if(nomor_perkara.includes('Pid')){
        if (pihak_dipublikasikan === "Y") {
            jenis = "pidana";
            teks = `panggilan, atas nama terdakwa ${truncatePihak(pihak2)}, perkara nomor ${truncateNomor(nomor_perkara)}, harap menuju ${ruang_sidang}, sidang akan segera dimulai.`;
        } else {
            jenis = "pidana anak";
            teks = `panggilan, untuk penuntut umum ${truncatePihak(pihak1)}, dan pihak perkara nomor ${truncateNomor(nomor_perkara)} ${jenis}, harap menuju ${ruang_sidang}, sidang akan segera dimulai.`;
        }
    }

    return teks;
}

const NewsItem = ({ teks, indehoi, onSpeechEnd }) => {
    const { Text, speechStatus, isInQueue, start, stop } = useSpeech({
        text: teks,
        autoPlay: true,
        preserveUtteranceQueue: true,
        volume: 1,
        voiceURI: "Google Bahasa Indonesia",
        lang: "id-ID",
        onStop: () => {
            onSpeechEnd();
        },
    });

    return (
        <>
            <p className='p-0 m-0 fs-1'>Nomor: <b>{<Text />}</b></p>
            <p className='p-0 m-0 fs-1'>Status: {speechStatus}</p>
            <p className='p-0 m-0 fs-1'>In Queue: {isInQueue.toString()}</p>
            <div style={{ display: "flex", columnGap: "0.5rem" }}>{!isInQueue ? <button id={indehoi} onClick={start}>Start</button> : <button onClick={stop}>Stop</button>}</div>
        </>
    );
};

const Square = () =>{
    const [statWS, setStatWS] = useState(null);
    const ws = useRef(null);
	const [panggilan, setPanggilan] = useState([]);

    //const today = new Date();
    const dite = new Date();
    const today = new Date(dite);
    today.setDate(dite.getDate() + 1);

    const handleWebSocketMessage = (event) => {
		const data = JSON.parse(event.data);
		switch (data.type) {
			case 'panggil':
                console.log('masuk panggilan');
				wsPanggil(data.perkara_id, data.ruang_sidang, data.userid);
			break;
			default:
		}
	};

    useEffect(()=>{
        const connectToLocalWebSocket = () => {
            ws.current = new WebSocket(`wss://192.168.3.7:83/`); // WS lokal
            const localTimeoutId = setTimeout(() => {
                if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
                    console.log('Local WebSocket failed to connect within 5 seconds, redirecting to local URL');
                    ws.current.close(); // menutup koneksi yang gagal
                    redirectToLocalURL(); // mengarahkan ke URL lokal
                }
            }, 5000);

            ws.current.onopen = () => {
                clearTimeout(localTimeoutId); // membersihkan timeout jika berhasil terhubung
                console.log('WebSocket connected locally');
                setStatWS(ws.current.readyState);
            };
            ws.current.onmessage = handleWebSocketMessage;
            ws.current.onclose = () => {
                console.log('WebSocket disconnected locally');
            };
            ws.current.onerror = (error) => {
                console.log('WebSocket error locally:', error);
                setStatWS(ws.current.readyState);
            };
        };

        if (ws.current === null) {
            ws.current = new WebSocket(`wss://103.127.133.166:3000/`); // koneksi utama

            const mainTimeoutId = setTimeout(() => {
                if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
                    console.log('Main WebSocket failed to connect within 5 seconds, switching to local');
                    ws.current.close(); // menutup koneksi yang gagal
                    connectToLocalWebSocket(); // mencoba koneksi ke lokal
                }
            }, 5000);

            ws.current.onopen = () => {
                clearTimeout(mainTimeoutId); // membersihkan timeout jika berhasil terhubung
                console.log('WebSocket connected');
                setStatWS(ws.current.readyState);
            };
            ws.current.onmessage = handleWebSocketMessage;
            ws.current.onerror = (error) => {
                console.log('WebSocket error:', error);
                setStatWS(ws.current.readyState);
            };
        }
		// eslint-disable-next-line
    }, []);

    const wsPanggil = async(id, ruangsidang, userid) => {
		try {
			let result = await ambil_data(`
				SELECT
					vdp.perkara_id,
					vdp.nomor_perkara,
					vdp.pihak_dipublikasikan,
					GROUP_CONCAT(
						DISTINCT CASE
							WHEN status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') THEN vpdh.nama
							ELSE NULL
						END
						SEPARATOR ' | '
					) AS pihak1,
					GROUP_CONCAT(
						DISTINCT CASE
							WHEN status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') THEN vpdh.nama
							ELSE NULL
						END
						SEPARATOR ' | '
					) AS pihak2
				FROM
					view_data_perkara vdp
				LEFT JOIN
					view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
				WHERE
					vdp.perkara_id = '${id}'
					AND
					vdp.tglSidang = '${formattedDate(today)}'
				`);
			if (result.length > 0) {
                let teksis = prepTeks(result[0].nomor_perkara, result[0].pihak_dipublikasikan, result[0].pihak1, result[0].pihak2, ruangsidang)
                let data = {
                    teks: teksis,
                    userid: userid
                };
				//let updatedResult = { ...result[0], ...additionalData };
                setPanggilan((prevPanggilan) => [data, ...prevPanggilan]);
                //console.log(data);
			} else {
				console.log('Error ambil data perkara');
			}
		} catch(error) {
			console.error('Error fetching', error);
		}
	};

    const {
        queue,
        clearQueue,
        dequeue,
    } = useQueue();

    const handleSpeechEnd = () => {
        console.log("Teks selesai dibacakan!");
    };

    return(
        <div>
            <div style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}>
                {panggilan.map(({ teks, userid }, index) => (
                    <NewsItem key={index} indehoi={index} teks={teks} onSpeechEnd={handleSpeechEnd} />
                ))}
            </div>
            <div style={{ display: "flex", flexDirection: "column", rowGap: "1rem", marginBlock: "2rem" }}>
                {queue.length ? (
                    queue.map(({ text }, i) => (
                        <div key={i}>
                            <button onClick={() => dequeue(i)}>Dequeue</button>
                            <span style={{ marginLeft: "1rem" }}>{text}</span>
                        </div>
                    ))
                ) : (
                    <div>Queue is Empty</div>
                )}
            </div>
            <button onClick={clearQueue}>Clear Queue</button>
        </div>
    )
};

export default Square;