import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Beranda from './js/Beranda';
import Papan from './js/Papan';
import Login from './js/Login';
import Lihat from './js/Lihat';
import Rank from './js/Rank';
import Square from './js/Square';
import { Context } from './js/services';

function App() {
  const [pagiNation, setPagi] = useState(0)
  return (
      <Router>
        <Context.Provider value={[pagiNation, setPagi]}>
          <Routes>
              <Route exact path='/' element={<Login />} />
              <Route exact path='/papan' element={<Papan />} />
              <Route exact path='/jadwal' element={<Beranda />} />
              <Route exact path='/home' element={<Lihat />} />
              <Route exact path='/rank' element={<Rank />} />
              <Route exact path='/square' element={<Square />} />
          </Routes>
        </Context.Provider>
      </Router>
  );
}

export default App;
