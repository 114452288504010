import React , { useState, useEffect, useRef } from 'react';
import { Table, TableCetak } from './table';
import Swal from 'sweetalert2';
import { ambil_data, tambah_data, edit_data, login ,formattedDate, formattedHariIni, query, query_lima, alertError, cekWS, redirectToLocalURL } from './services';
import DatePicker from 'react-datepicker';
import { id } from 'date-fns/locale';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { createColumnHelper } from '@tanstack/table-core';

const Papan = () =>{
    const [data, setData] = useState([]);
    const [dataCetak, setDataCetak] = useState([]);
    const [dataExport, setDataExport] = useState([]);
    const ws = useRef(null);
    const [statWS, setStatWS] = useState(null);

    const today = new Date();
    /*const dite = new Date();
    const today = new Date(dite);
    today.setDate(dite.getDate() + 2);*/

    const currentTime = today.getTime();

    const [loggedIn, setLoggedin] = useState(false);
    const [petugas, setPetugas] = useState('');
    const [tempPetugas, setTempPetugas] = useState('');

    const [startDate, setStartDate] = useState(today);
    //const [kolo, setKolo] = useContext(Context);

    const fetch_data = async() =>{
		try{
			const result = await ambil_data(query(formattedDate(today)));
            const result2 = await ambil_data(query_lima(formattedDate(today)));
			setData(result);
            setDataCetak(result);
            setDataExport(result2);
		} catch(error){
			console.error('Error fetching', error);
            alertError(error);
		}
	};

	useEffect(() => {
        const loggedIn = localStorage.getItem('loggedIn') === 'true';
        if (!loggedIn) {
			popUpLogin();
		} else {
            const loginTime = localStorage.getItem('loginTime');
            const threeHours = 3 * 60 * 60 * 1000;
            if(loginTime && (currentTime - loginTime) > threeHours){
                logout();
            }else{
                setLoggedin(true);
            }
		}
        // eslint-disable-next-line
	}, []);

    //login

    const popUpLogin = () => {
        let usernameInput, passwordInput, eyePassword, i;
        let user = [];
        Swal.fire({
            title: 'Login ..',
            html: `
                    <p>Silahkan login dengan Akun SIPP</p>
                <div class="row w-auto p-1">
                    <div class="col px-3">
                        <input type="text" id="username" class="form-control mb-2" placeholder="Username">
                        <div class="input-group mb-1">
                            <input type="password" id="password" class="form-control" placeholder="Password">
                            <span id="eyepassword" class="input-group-text btn btn-outline-secondary"><i id="eye" class="fa fa-eye-slash" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>
            `,
            confirmButtonText: 'Login',
            confirmButtonColor: '#fd7e14',
            focusConfirm: false,
            showLoaderOnConfirm: true,
			allowOutsideClick: false,
            didOpen: () => {
                const popup = Swal.getPopup();
                usernameInput = popup.querySelector('#username');
                passwordInput = popup.querySelector('#password');
                eyePassword = popup.querySelector('#eyepassword');
                i = popup.querySelector('#eye');
                usernameInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                passwordInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                usernameInput.onchange = async() => {
                    try{
                        let result = await ambil_data(`SELECT*FROM view_data_user WHERE username = '${usernameInput.value}'`);
                        if(!result.length > 0){
                            Swal.showValidationMessage(`Username tidak ditemukan!`);
                        }else{
                            Swal.resetValidationMessage()
                            user = result[0];
                        }
                    } catch (error){
                        console.error('Error :', error);
                        alertError(error);
                    }
                };
                eyePassword.onclick = (event) =>{
                    event.preventDefault();
                    if(passwordInput.getAttribute('type') === 'password'){
                        passwordInput.setAttribute('type', 'text');
                        i.classList.remove('fa-eye-slash');
                        i.classList.add('fa-eye');
                    }else{
                        passwordInput.setAttribute('type', 'password');
                        i.classList.remove('fa-eye');
                        i.classList.add('fa-eye-slash');
                    }
                    
                };
            },
            preConfirm: async() => {
                const username = usernameInput.value;
                const password = passwordInput.value;
                if (!username || !password) {
                    Swal.showValidationMessage(`Username / Password tidak boleh kosong!`);
                }else{
                    try{
                        let result = await login(password, user);
                        if(result.success){
                            setLoggedin(true);
                            const newUser = {
                                userid: user.userid,
                                fullname: user.fullname,
                                jenis: user.group_name,
                            };
                            localStorage.setItem('userIn', JSON.stringify(newUser));
                        }else{
                            Swal.showValidationMessage(`Username / Password salah!`);
                        }
                    } catch (error){
                        console.error('Error :', error);
                        alertError(error);
                    }
                }
            },
        });
    };

    useEffect(() =>{
        if (loggedIn) {
            localStorage.setItem('loggedIn', true.toString());
            localStorage.setItem('loginTime', currentTime);
            fetch_data();
    
            const connectToLocalWebSocket = () => {
                ws.current = new WebSocket(`wss://192.168.3.7:83/`); // WS lokal
                const localTimeoutId = setTimeout(() => {
                    if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
                        console.log('Local WebSocket failed to connect within 5 seconds, redirecting to local URL');
                        ws.current.close(); // menutup koneksi yang gagal
                        redirectToLocalURL(); // mengarahkan ke URL lokal
                    }
                }, 5000);
    
                ws.current.onopen = () => {
                    clearTimeout(localTimeoutId); // membersihkan timeout jika berhasil terhubung
                    console.log('WebSocket connected locally');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onmessage = handleWebSocketMessage;
                ws.current.onclose = () => {
                    console.log('WebSocket disconnected locally');
                };
                ws.current.onerror = (error) => {
                    console.log('WebSocket error locally:', error);
                    setStatWS(ws.current.readyState);
                };
            };
    
            if (ws.current === null) {
                ws.current = new WebSocket(`wss://103.127.133.166:3000/`); // koneksi utama
    
                const mainTimeoutId = setTimeout(() => {
                    if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
                        console.log('Main WebSocket failed to connect within 5 seconds, switching to local');
                        ws.current.close(); // menutup koneksi yang gagal
                        connectToLocalWebSocket(); // mencoba koneksi ke lokal
                    }
                }, 5000);
    
                ws.current.onopen = () => {
                    clearTimeout(mainTimeoutId); // membersihkan timeout jika berhasil terhubung
                    console.log('WebSocket connected');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onmessage = handleWebSocketMessage;
                ws.current.onclose = () => {
                    ws.current = null;
                    if (mainTimeoutId) clearTimeout(mainTimeoutId); // membersihkan timeout jika ditutup
                    connectToLocalWebSocket();
                };
                ws.current.onerror = (error) => {
                    console.log('WebSocket error:', error);
                    setStatWS(ws.current.readyState);
                };
            }
        }
        // eslint-disable-next-line
    }, [loggedIn]);

    //ws

    const handleWebSocketMessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.type) {
			case 'update':
				//fetch_data();
			break;
            default:
        }
    };

    const truncateName = (name) => {
        const maxLength = 20;
        if (name.length > maxLength) {
            return name.substring(0, maxLength) + '...';
        } else {
            return name;
        }
    };

    const columnHelper = createColumnHelper();
    
    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'Nomor',
            accessorKey: 'no',
            cell : ({row}) =>{
                return(                    
                    <div className='row align-items-center'>
                        <div className='col col-2 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>No.</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.index + 1}.
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: false,
            filterFn: 'includesString',
            enableHiding: true,
        }),
        columnHelper.accessor('nomor_perkara', {
            id: 'nomor_perkara',
            header: () => 'Nomor Perkara',
            accessorKey: 'nomor_perkara',
            cell: ({ row }) => {
                const nomor = row.original.nomor_perkara;
                let textColor = 'kosong';
    
                if (nomor.includes('Pid')) {
                    textColor = 'nomor_pidana';
                } else if (nomor.includes('Pdt')) {
                    textColor = 'nomor_perdata';
                } else if (nomor.includes('Pdt.P')) {
                    textColor = 'nomor_permo';
                }
    
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Nomor Perkara</p>
                        </div>
                        <div className='col col-lg-auto'>
                            <div className={`${textColor}`}>
                                {nomor}
                            </div>
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: true,
            filterFn: 'fuzzy',
            enableHiding: true,
        }),
        columnHelper.accessor('agenda', {
            id: 'agenda',
            header: () => 'Agenda',
            accessorKey: 'agenda',
            cell : ({row}) =>{
                return(
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Agenda</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.original.agenda}
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: true,
            filterFn: 'fuzzy',
            enableHiding: true,
        }),
        columnHelper.accessor('pihak1', {
            id: 'pihak1',
            header: () => 'Pihak Pertama',
            accessorKey: 'pihak1',
            cell: ({ row }) => {
                const pihak1_hadir = row.original.pihak1_hadir ? row.original.pihak1_hadir.split('|') : [];
                const pihak1_tdk_hadir = row.original.pihak1_tdk_hadir ? row.original.pihak1_tdk_hadir.split('|') : [];
                const pihak1 = [...pihak1_hadir, ...pihak1_tdk_hadir];
        
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Pihak 1</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {pihak1.map((pihak, index) => {
                                const buttonColor = pihak1_hadir.includes(pihak) ? 'btn-success' : 'btn-danger';
                                const pihakLabel = row.original.pihak_dipublikasikan === 'T' ? 'Disamarkan' : truncateName(pihak);
                                return (
                                    <button className={`btn ${buttonColor} m-2`} key={index} onClick={() => handlePihakClick(row.original.perkara_id, pihak, buttonColor)}>
                                        {pihakLabel}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: true,
            filterFn: 'fuzzy',
            enableHiding: true,
        }),
        columnHelper.accessor('pihak2', {
            id: 'pihak2',
            header: () => 'Pihak Kedua',
            accessorKey: 'pihak2',
            cell: ({ row }) => {
                const pihak2_hadir = row.original.pihak2_hadir ? row.original.pihak2_hadir.split('|') : [];
                const pihak2_tdk_hadir = row.original.pihak2_tdk_hadir ? row.original.pihak2_tdk_hadir.split('|') : [];
                const pihak2 = [...pihak2_hadir, ...pihak2_tdk_hadir];
        
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Pihak 2</p>
                        </div>
                        <div className='col col-lg-auto'>
                        {pihak2.map((pihak, index) => {
                            const buttonColor = pihak2_hadir.includes(pihak) ? 'btn-success' : 'btn-danger';
                            const pihakLabel = row.original.pihak_dipublikasikan === 'T' ? 'Disamarkan' : truncateName(pihak);
                                return (
                                    <button className={`btn ${buttonColor} m-2`} key={index} onClick={() => handlePihakClick(row.original.perkara_id, pihak, buttonColor)}>
                                        {pihakLabel}
                                    </button>
                                );
                        })}
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: true,
            filterFn: 'fuzzy',
            enableHiding: true,
        }),
        columnHelper.accessor('hakim', {
            id: 'hakim',
            header: () => 'Hakim',
            accessorKey: 'hakim',
            cell: ({ row }) => {
                const hakims = row.original.hakim.split(/<\/?br\s*\/?>/i);
                const formattedNames = hakims.map(hakim => {
                    const nameMatch = hakim.match(/:\s*(.+)$/);
                    if (nameMatch) {
                        const formattedName = nameMatch[1].replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                        return formattedName.replace(/,\s*$/, '');
                    }
                    return '';
                });
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Hakim</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {formattedNames.map((name, index) => (
                                <p key={index} className='p-0 m-0 text-black'>
                                    {name}
                                </p>
                            ))}
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: false,
            filterFn: 'includesString',
            enableHiding: true,
        }),
        columnHelper.accessor('panitera_pengganti', {
            id: 'panitera_pengganti',
            header: () => 'Panitera Pengganti',
            accessorKey: 'panitera_pengganti',
            cell: ({ row }) => {
                const panitera_pengganti = row.original.panitera_pengganti.split(':').pop().trim().replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>PP</p>
                        </div>
                        <div className='col col-lg-auto'>
                            <p className='p-0 m-0 text-black'>
                                {panitera_pengganti}
                            </p>
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: false,
            filterFn: 'includesString',
            enableHiding: true,
        }),
    ];

    const columVis = {
        'no': true,
        'nomor_perkara': true,
        'agenda': true,
        'pihak1': true,
        'pihak2': true,
        'hakim': true,
    };
    
    const handlePihakClick = (perkaraId, pihak, buttonColor) => {
        let title = '';
        let icon = '';
        let confirmtext = '';
        let kehadiran = '';
        if (buttonColor === 'btn-danger') {
            title = 'Konfirmasi Kehadiran';
            icon = 'question';
            confirmtext = 'Ya, sudah hadir';
            kehadiran = 'Y';
        }else{
            title = 'Ubah Kehadiran';
            icon = 'warning';
            confirmtext = 'Tidak, tidak hadir';
            kehadiran = 'T';
        }

        Swal.fire({
            title: `${title}`,
            text: `Kehadiran atas nama : ${pihak}`,
            icon: `${icon}`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `${confirmtext}`,
            cancelButtonText: 'Batal'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let hasil = await ambil_data(`
                        SELECT
                            pihak_id,
                            perkara_id,
                            status_perkara,
                            nama,
                            '${kehadiran}' AS kehadiran,
                            '${formattedDate(today)}' AS tglSidang
                        FROM
                            view_data_pihak vdph
                        WHERE
                            (
                                perkara_id = ${perkaraId}
                                AND
                                nama LIKE '%${pihak}%'
                            )
                    `);

                    const fungsi_ubah = async() =>{
                        let id = await ambil_data(`
                            SELECT
                                id
                            FROM
                                data_kehadiran
                            WHERE
                            (
                                perkara_id = ${perkaraId}
                                AND
                                nama LIKE '%${pihak}%'
                                AND tglSidang = '${formattedDate(today)}'
                            )
                        `);
                        ubah_hadir(id[0]['id'], hasil);
                    }

                    if(buttonColor === 'btn-danger'){
                        let cek = await ambil_data(`
                            SELECT
                                *
                            FROM
                                data_kehadiran
                            WHERE(
                                perkara_id = ${perkaraId}
                                AND
                                nama LIKE '%${pihak}%'
                                AND tglSidang = '${formattedDate(today)}'
                            )
                        `);
                        if(cek.length > 0){
                            fungsi_ubah();
                        }else{
                            konfirmasi_hadir(hasil)
                        }
                    }else{
                        fungsi_ubah();
                    }
                } catch (error) {
                    console.error('Error', error);
                    alertError(error);
                }
            }
        });
    };

    const konfirmasi_hadir = async(data) =>{
        try{
            let result = await tambah_data('data_kehadiran', data);
            console.log(result);
            if(result.success){
                fetch_data();
                update();
            }else{
                console.log('Error :', result.message);
                alertError(result.message);
            }
        } catch (error){
            console.error('Error :', error);
            alertError(error);
        }
    };

    const ubah_hadir = async(id, data) =>{
        try {
            let result = await edit_data('data_kehadiran', id, data);
            if(result && result.success){
                fetch_data();
                update();
            } else {
                console.log('Error :', result.message);
                alertError(result.message);
            }
        } catch (error) {
            console.error('Error :', error);
            alertError(error);
        }
    };

    const update = () =>{
        if (ws.current.readyState === WebSocket.OPEN) {
			ws.current.send(JSON.stringify({ type: 'update' }));
		} else {
			console.error('Error gagal kirim status update');
            alertError('Error gagal kirim status update');
		}
    };

    const logout = () =>{
        if(loggedIn){
            setLoggedin(false);
        }
        setData([]);
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('userIn');
        localStorage.removeItem('loginTime');
        if(ws.current !== null){
            ws.current.close();
        }
        ws.current = null;
        popUpLogin();
    };

    const simpanPetugas = () =>{
        setPetugas(tempPetugas);
    };

    const columnscetak = [
        { //nomor_perkara
            header: 'Nomor Perkara',
            accessorKey: 'nomor_perkara',
            width: 100,
            cell: ({ row }) => {
                const nomor = row.original.nomor_perkara;
                let textColor = 'kosong';
    
                if (nomor.includes('Pid')) {
                    textColor = 'nomor_pidana';
                } else if (nomor.includes('Pdt.P')) {
                    textColor = 'nomor_permo';
                } else if (nomor.includes('Pdt')) {
                    textColor = 'nomor_perdata';
                }
    
                return (
                    <div className={`${textColor}`}>
                        {nomor}
                    </div>
                );
            },
        },
        { //agenda
            header: 'Agenda',
            accessorKey: 'agenda',
            cell : ({row}) =>{
                return(
                    <div className='col col-lg-auto'>
                        {row.original.agenda}
                    </div>
                );
            }
        },
        { //pihakpertama
            header: 'Pihak Pertama',
            accessorKey: 'pihak1_hadir',
            cell: ({ row }) => {
                const pihak1_hadir = row.original.pihak1_hadir ? row.original.pihak1_hadir.split('|') : [];
                const pihak1_tdk_hadir = row.original.pihak1_tdk_hadir ? row.original.pihak1_tdk_hadir.split('|') : [];
                const pihak1 = [...pihak1_hadir, ...pihak1_tdk_hadir];
        
                return (
                    <>
                        {pihak1.map((pihak, index) => {
                            return (
                                <p key={index} className='p-0 m-0 text-black'>
                                    - {pihak}
                                </p>
                            );
                        })}
                    </>
                );
            }
        },
        { //pihakkedua
            header: 'Pihak Kedua',
            accessorKey: 'pihak2',
            cell: ({ row }) => {
                const pihak2_hadir = row.original.pihak2_hadir ? row.original.pihak2_hadir.split('|') : [];
                const pihak2_tdk_hadir = row.original.pihak2_tdk_hadir ? row.original.pihak2_tdk_hadir.split('|') : [];
                const pihak2 = [...pihak2_hadir, ...pihak2_tdk_hadir];
        
                return (
                    <>
                        {pihak2.map((pihak, index) => {
                            return (
                                <p key={index} className='p-0 m-0 text-black'>
                                    - {pihak}
                                </p>
                            );
                        })}
                    </>
                );
            }
        },
        { //hakim
            header: 'Hakim',
            accessorKey: 'hakim',
            cell: ({ row }) => {
                const hakims = row.original.hakim.split(/<\/?br\s*\/?>/i);
                const formattedNames = hakims.map(hakim => {
                    const nameMatch = hakim.match(/:\s*(.+)$/);
                    if (nameMatch) {
                        const formattedName = nameMatch[1].replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                        return formattedName.replace(/,\s*$/, '');
                    }
                    return '';
                });
                return (
                    <>
                        {formattedNames.map((name, index) => (
                            <p key={index} className='p-0 m-0 text-black'>
                                - {name}
                            </p>
                        ))}
                    </>
                );
            }
        },
        { //pp
            header: 'Panitera Pengganti', accessorKey: 'panitera_pengganti',
            cell: ({ row }) => {
                const panitera_pengganti = row.original.panitera_pengganti.split(':').pop().trim().replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                return (
                    <>
                        <p className='p-0 m-0 text-black'>
                            {panitera_pengganti}
                        </p>
                    </>
                );
            }
        },
    ];

    const cetak = () => {
        return new Promise((resolve, reject) => {
            const content = document.getElementById('cetakIni');
            if (content) {
                const iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

                const styleSheets = Array.from(document.styleSheets).map((styleSheet) => {
                    try {
                        return Array.from(styleSheet.cssRules).map((rule) => rule.cssText).join(' ');
                    } catch (e) {
                        console.log(e);
                        return '';
                    }
                }).filter(Boolean).join(' ');
    
                iframeDocument.open();
                iframeDocument.write(`
                    <html>
                        <head>
                            <style>
                                @page {
                                    size: landscape;
                                    margin: 0;
                                    padding: 0;
                                }
                            </style>
                            <style>${styleSheets}</style>
                        </head>
                        <body>
                            ${content.outerHTML}
                        </body>
                    </html>
                `);
                iframeDocument.close();
    
                iframe.onload = () => {
                    iframe.contentWindow.focus();
                    iframe.contentWindow.print();
                    document.body.removeChild(iframe);
                    resolve();
                };
            } else {
                console.error('Element dengan id "cetakIni" tidak ditemukan.');
                reject('Gagal cetak');
            }
        });
        /*const content = document.getElementById('cetakIni');
        if (content) {
            const newWindow = window.open('', '_blank');
            if (newWindow) {
                const styleSheets = Array.from(document.styleSheets).map((styleSheet) => {
                    try {
                        return Array.from(styleSheet.cssRules).map((rule) => rule.cssText).join(' ');
                    } catch (e) {
                        console.log(e);
                        return '';
                    }
                }).filter(Boolean).join(' ');
    
                newWindow.document.write(`
                    <html>
                        <head>
                            <title>Jadwal Sidang Pengadilan Negeri Banyumas</title>
                            <style>${styleSheets}</style>
                        </head>
                        <body>
                            ${content.outerHTML}
                        </body>
                    </html>
                `);
    
                // Menambahkan perintah print setelah konten dimuat
                newWindow.onload = function() {
                    newWindow.print();
                };
            } else {
                console.error('Gagal membuka tab baru.');
            }
        } else {
            console.error('Element dengan id "cetakIni" tidak ditemukan.');
        }*/
    };

    useEffect(()=>{
        if(startDate !== null){
            fetch_data_cetak(formattedDate(startDate));
        }
        // eslint-disable-next-line
    }, [startDate]);

    const fetch_data_cetak = async(tgl) =>{
        try{
            const result = await ambil_data(query(tgl));
            const result2 = await ambil_data(query_lima(tgl));
            setDataCetak(result);
            setDataExport(result2);
        } catch(error){
            console.error('Error fetching', error);
            alertError(error);
        }
    };

    useEffect(()=>{
        if(statWS !== null){
            cekWS(statWS);
        }
        // eslint-disable-next-line
    }, [statWS]);

    const handleExport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dataExport);

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `jadwal_sidang(${formattedHariIni(startDate)}).xlsx`);
    };

    return(
        <div>
			<div className="limiter">
				<div className="background-container">
					<div className="wrap">
                        <div className='container'>
                            <div className='row text-center'>
								<div className='col'>
									<p className='p-0 m-0 fw-bold fs-2 text-black'>Jadwal Sidang Pengadilan Negeri Banyumas</p>
                                    <p className='p-0 m-0 fw-bold fs-4 text-black'>Tanggal {formattedHariIni(today)}</p>   
                                    <div className='row text-end mt-3'>
                                        <div className='col'>
                                            <p className='p-0 m-0 fs-8 text-black'><b>*</b>Status Kehadiran Pihak : <span className='badge text-bg-danger text-wrap'>Merah</span> Belum Hadir | <span className='badge text-bg-success text-wrap'>Hijau</span> Sudah Hadir</p>
                                        </div>
                                    </div>
                                        <Table columns={columns} data={data} columnVis={columVis} statVis={0}/>
								</div>
							</div>
                            <div className='row'>
								<div className='col'>
                                    <p className='p-0 m-0 fs-8 text-dark'>Petugas Jaga Sidang : {petugas ? petugas : 'Belum ada petugas'}</p>
                                    {petugas === '' && (
                                        <div className="input-group w-50 mb-3">
                                            <input type='text' className='form-control form-control-sm' id="inputPetugas" onChange={(event) => setTempPetugas(event.target.value)} placeholder='Masukan Nama ..' />
                                            <button className="btn btn-secondary" type="button" onClick={simpanPetugas} id="buttonSimpan">Simpan</button>
                                        </div>
                                    )}
                                </div>
                                <div className='col d-flex align-items-center justify-content-end flex-wrap'>
                                    <div className='picker'>
                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat='d MMMM yyyy' className='form-control' locale={id} />
                                    </div>
                                    <button className='btn btn-primary ms-1' onClick={() => cetak()} type='button'>Cetak</button>
                                    <button className='btn btn-secondary ms-1' onClick={() => handleExport()} type='button'>Unduh</button>
                                </div>
							</div>
                            <div className='row mb-3'>								
                                <div className='col text-end'>
                                    <p className='copy_right p-0 m-0 text-dark'>Copyright @sijenggo86</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>                         
                <div id="cetakIni" className='hidethis'>
                    <div className='container py-2 cetakIni'>
                        <div className='row p-0 m-0 text-center'>
                            <div className='col'>
                                <p className='p-0 m-0 fw-bold fs-3 text-black'>Jadwal Sidang Pengadilan Negeri Banyumas</p>
                                <p className='p-0 m-0 fw-bold fs-5 text-black'>Tanggal {formattedHariIni(startDate)}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col fs-cetak'>
                                <TableCetak columns={columnscetak} data={dataCetak} />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col'>
                                <p className='p-0 m-0 fs-8 text-dark text-end'>Petugas Jaga Sidang : {petugas ? petugas : 'Belum ada petugas'}</p>
                            </div>
                        </div>                        
                        <div className='row mb-3'>								
                            <div className='col text-end'>
                                <p className='copy_right p-0 m-0 text-dark'>Copyright @sijenggo86</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
};

export default Papan;