import React, { useState, useEffect, useRef  } from 'react';
import { Table } from './table';
import Swal from 'sweetalert2';
import { ambil_data, edit_data, login, formattedDate, formattedHariIni, alertError, alertNotif, cekWS, truncateName, query_sidang, tambah_data, redirectToLocalURL } from './services';
import { createColumnHelper } from '@tanstack/react-table';

const Login = () =>{
    const [loggedIn, setLoggedin] = useState(false);
    const [data, setData] = useState([]);
    const [data0, setData0] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const userIn = JSON.parse(localStorage.getItem('userIn')) || [];
    const ws = useRef(null);
    const [statWS, setStatWS] = useState(null);

    const [pageStat, setPage] = useState(0);
    const [prevPage, setPrevPage] = useState(0);

    const today = new Date();
    /*const dite = new Date();
    const today = new Date(dite);
    today.setDate(dite.getDate() - 1);*/

    const currentTime = today.getTime();

    const columnHelper = createColumnHelper();

    //useeffect render pertama

    useEffect(() =>{
        const loggedIn = localStorage.getItem('loggedIn') === 'true';
        if (!loggedIn) {
			popUpLogin();
		} else {
            const loginTime = localStorage.getItem('loginTime');
            const threeHours = 3 * 60 * 60 * 1000;
            if(loginTime && (currentTime - loginTime) > threeHours){
                logout();
            }else{
                setLoggedin(true);
            }
		}
        // eslint-disable-next-line
    }, []);

    //ws

    const handleWebSocketMessage = (event) => {
		const data = JSON.parse(event.data);
		switch (data.type) {
			case 'selesai':
				if(data.userid === userIn.userid){
                    Swal.close();
                }
			break;
			case 'update':
				fetch_data();
			break;
			default:
		}
	};

    //login

    const popUpLogin = () => {
        let usernameInput, passwordInput, eyePassword, i;
        let user = [];
        Swal.fire({
            title: 'Login ..',
            html: `
                <p>Silahkan login dengan Akun SIPP</p>
                <div class="row w-auto p-1">
                    <div class="col px-3">
                        <input type="text" id="username" class="form-control mb-2" placeholder="Username">
                        <div class="input-group mb-1">
                            <input type="password" id="password" class="form-control" placeholder="Password">
                            <span id="eyepassword" class="input-group-text btn btn-outline-secondary"><i id="eye" class="fa fa-eye-slash" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>
            `,
            confirmButtonText: 'Login',
            confirmButtonColor: '#fd7e14',
            focusConfirm: false,
            showLoaderOnConfirm: true,
			allowOutsideClick: false,
            didOpen: () => {
                const popup = Swal.getPopup();
                usernameInput = popup.querySelector('#username');
                passwordInput = popup.querySelector('#password');
                eyePassword = popup.querySelector('#eyepassword');
                i = popup.querySelector('#eye');
                usernameInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                passwordInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                usernameInput.onchange = async() => {
                    try{
                        let result = await ambil_data(`SELECT*FROM view_data_user WHERE username = '${usernameInput.value}'`);
                        if(!result.length > 0){
                            Swal.showValidationMessage(`Username tidak ditemukan!`);
                        }else{
                            Swal.resetValidationMessage()
                            user = result[0];
                        }
                    } catch (error){
                        console.error('Error :', error);
                        alertError(error);
                    }
                };
                eyePassword.onclick = (event) =>{
                    event.preventDefault();
                    if(passwordInput.getAttribute('type') === 'password'){
                        passwordInput.setAttribute('type', 'text');
                        i.classList.remove('fa-eye-slash');
                        i.classList.add('fa-eye');
                    }else{
                        passwordInput.setAttribute('type', 'password');
                        i.classList.remove('fa-eye');
                        i.classList.add('fa-eye-slash');
                    }
                    
                };
            },
            preConfirm: async() => {
                const username = usernameInput.value;
                const password = passwordInput.value;
                if (!username || !password) {
                    Swal.showValidationMessage(`Username / Password tidak boleh kosong!`);
                }else{
                    try{
                        let result = await login(password, user);
                        if(result.success){
                            setLoggedin(true);
                            const newUser = {
                                userid: user.userid,
                                fullname: user.fullname,
                                jenis: user.group_name,
                            };
                            localStorage.setItem('userIn', JSON.stringify(newUser));
                        }else{
                            Swal.showValidationMessage(`Username / Password salah!`);
                        }
                    } catch (error){
                        console.error('Error :', error);
                        alertError(error);
                    }
                }
            },
        });
    };

    useEffect(() =>{
        if (loggedIn) {
            localStorage.setItem('loggedIn', true.toString());
            localStorage.setItem('loginTime', currentTime);
            fetch_data();
    
            const connectToLocalWebSocket = () => {
                ws.current = new WebSocket(`wss://192.168.3.7:83/`); // WS lokal
                const localTimeoutId = setTimeout(() => {
                    if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
                        console.log('Local WebSocket failed to connect within 5 seconds, redirecting to local URL');
                        ws.current.close(); // menutup koneksi yang gagal
                        redirectToLocalURL(); // mengarahkan ke URL lokal
                    }
                }, 5000);
    
                ws.current.onopen = () => {
                    clearTimeout(localTimeoutId); // membersihkan timeout jika berhasil terhubung
                    console.log('WebSocket connected locally');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onmessage = handleWebSocketMessage;
                ws.current.onclose = () => {
                    console.log('WebSocket disconnected locally');
                };
                ws.current.onerror = (error) => {
                    console.log('WebSocket error locally:', error);
                    setStatWS(ws.current.readyState);
                };
            };
    
            if (ws.current === null) {
                ws.current = new WebSocket(`wss://103.127.133.166:3000/`); // koneksi utama
    
                const mainTimeoutId = setTimeout(() => {
                    if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
                        console.log('Main WebSocket failed to connect within 5 seconds, switching to local');
                        ws.current.close(); // menutup koneksi yang gagal
                        connectToLocalWebSocket(); // mencoba koneksi ke lokal
                    }
                }, 5000);
    
                ws.current.onopen = () => {
                    clearTimeout(mainTimeoutId); // membersihkan timeout jika berhasil terhubung
                    console.log('WebSocket connected');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onmessage = handleWebSocketMessage;
                ws.current.onclose = () => {
                    ws.current = null;
                    if (mainTimeoutId) clearTimeout(mainTimeoutId); // membersihkan timeout jika ditutup
                    connectToLocalWebSocket();
                };
                ws.current.onerror = (error) => {
                    console.log('WebSocket error:', error);
                    setStatWS(ws.current.readyState);
                };
            }
        }
        // eslint-disable-next-line
    }, [loggedIn]);

    const fetch_data = async() =>{
		try{
			const result0 = await ambil_data(query_sidang(formattedDate(today), userIn.fullname, 0));
			const result1 = await ambil_data(query_sidang(formattedDate(today), userIn.fullname, 1));
			const result2 = await ambil_data(query_sidang(formattedDate(today), userIn.fullname, 2));
			setData0(result0);
            setData1(result1);
            setData2(result2);
		} catch(error){
			console.error('Error fetching', error);
            alertError(error);
		}
	};

    //data sidang
    
    const columns = [
        columnHelper.accessor('status', {
            id: 'status',
            header: () => 'Status',
            accessorKey: 'status',
            cell : ({row}) =>{
                return(                    
                    <div className="d-grid gap-2">
                        <button className="btn btn-outline-success fs-4" type="button" 
                            onClick={() =>updateStatus(row.original.id, row.original.perkara_id, row.original.nomor_perkara, 'selesai')
                            }>
                                Sidang Selesai <i className="fa-solid fa-flag-checkered fa-beat"></i>
                        </button>
                    </div>
                );
            },
            enableGlobalFilter: false,
            enableHiding: true,
        }),
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'Nomor',
            accessorKey: 'no',
            cell : ({row}) =>{
                return(                    
                    <div className='row align-items-center'>
                        <div className='col col-2 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>No.</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.index + 1}.
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: false,
            filterFn: 'includesString',
            enableHiding: true,
        }),
        columnHelper.accessor('nomor_perkara', {
            id: 'nomor_perkara',
            header: () => 'Nomor Perkara',
            accessorKey: 'nomor_perkara',
            cell: ({ row }) => {
                const nomor = row.original.nomor_perkara;
                let textColor = 'kosong';
    
                if (nomor.includes('Pid')) {
                    textColor = 'nomor_pidana';
                } else if (nomor.includes('Pdt')) {
                    textColor = 'nomor_perdata';
                } else if (nomor.includes('Pdt.P')) {
                    textColor = 'nomor_permo';
                }
    
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Nomor Perkara</p>
                        </div>
                        <div className='col col-lg-auto'>
                            <div className={`${textColor}`}>
                                {nomor}
                            </div>
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: true,
            filterFn: 'fuzzy',
            enableHiding: true,
        }),
        columnHelper.accessor('agenda', {
            id: 'agenda',
            header: () => 'Agenda',
            accessorKey: 'agenda',
            cell : ({row}) =>{
                return(
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Agenda</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.original.agenda}
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: true,
            filterFn: 'fuzzy',
            enableHiding: true,
        }),
        columnHelper.accessor('pihak1', {
            id: 'pihak1',
            header: () => 'Pihak Pertama',
            accessorKey: 'pihak1',
            cell: ({ row }) => {
                const pihak1_hadir = row.original.pihak1_hadir ? row.original.pihak1_hadir.split('|') : [];
                const pihak1_tdk_hadir = row.original.pihak1_tdk_hadir ? row.original.pihak1_tdk_hadir.split('|') : [];
                const pihak1 = [...pihak1_hadir, ...pihak1_tdk_hadir];
        
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Pihak 1</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {pihak1.map((pihak, index) => {
                                const buttonColor = pihak1_hadir.includes(pihak) ? 'btn-success' : 'btn-danger';
                               // const pihakLabel = row.original.pihak_dipublikasikan === 'T' ? 'Disamarkan' : truncateName(pihak);
                                return (
                                    <button className={`btn ${buttonColor} m-2`} key={index} onClick={() => handlePihakClick(pihak, buttonColor)}>
                                        {truncateName(pihak)}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: true,
            filterFn: 'fuzzy',
            enableHiding: true,
        }),
        columnHelper.accessor('pihak2', {
            id: 'pihak2',
            header: () => 'Pihak Kedua',
            accessorKey: 'pihak2',
            cell: ({ row }) => {
                const pihak2_hadir = row.original.pihak2_hadir ? row.original.pihak2_hadir.split('|') : [];
                const pihak2_tdk_hadir = row.original.pihak2_tdk_hadir ? row.original.pihak2_tdk_hadir.split('|') : [];
                const pihak2 = [...pihak2_hadir, ...pihak2_tdk_hadir];
        
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Pihak 2</p>
                        </div>
                        <div className='col col-lg-auto'>
                        {pihak2.map((pihak, index) => {
                            const buttonColor = pihak2_hadir.includes(pihak) ? 'btn-success' : 'btn-danger';
                            //const pihakLabel = row.original.pihak_dipublikasikan === 'T' ? 'Disamarkan' : truncateName(pihak);
                            return (
                                <button className={`btn ${buttonColor} m-2`} key={index} onClick={() => handlePihakClick(pihak, buttonColor)}>
                                    {truncateName(pihak)}
                                </button>
                            );
                        })}
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: true,
            filterFn: 'fuzzy',
            enableHiding: true,
        }),
        columnHelper.accessor('hakim', {
            id: 'hakim',
            header: () => 'Hakim',
            accessorKey: 'hakim',
            cell: ({ row }) => {
                const hakims = row.original.hakim.split(/<\/?br\s*\/?>/i);
                const formattedNames = hakims.map(hakim => {
                    const nameMatch = hakim.match(/:\s*(.+)$/);
                    if (nameMatch) {
                        const formattedName = nameMatch[1].replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                        return formattedName.replace(/,\s*$/, '');
                    }
                    return '';
                });
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Hakim</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {formattedNames.map((name, index) => (
                                <p key={index} className='p-0 m-0 text-black'>
                                    {name}
                                </p>
                            ))}
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: false,
            filterFn: 'includesString',
            enableHiding: true,
        }),
        columnHelper.accessor('panitera_pengganti', {
            id: 'panitera_pengganti',
            header: () => 'Panitera Pengganti',
            accessorKey: 'panitera_pengganti',
            cell: ({ row }) => {
                const panitera_pengganti = row.original.panitera_pengganti.split(':').pop().trim().replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>PP</p>
                        </div>
                        <div className='col col-lg-auto'>
                            <p className='p-0 m-0 text-black'>
                                {panitera_pengganti}
                            </p>
                        </div>
                    </div>
                );
            },
            enableGlobalFilter: false,
            filterFn: 'includesString',
            enableHiding: true,
        }),
        columnHelper.accessor('aksi', {
            id: 'aksi',
            header: () => 'Aksi',
            accessorKey: 'aksi',
            cell: ({ row }) => {
                return (
                    <div className="d-grid gap-2">
                        <button className="btn btn-primary fs-4" type="button" onClick={() => handlePanggil(row.original.id, row.original.perkara_id, row.original.nomor_perkara)}>
                            Panggil <i className="fa fa-circle-play"></i>
                        </button>
                    </div>
                );
            },
            enableGlobalFilter: false,
            enableHiding: true,
        }),
        columnHelper.accessor('aksi2', {
            id: 'aksi2',
            header: () => 'Aksi2', 
            accessorKey: 'aksi2',
            cell: ({ row }) => {
                const hakims = row.original.hakim ? row.original.hakim.split(/<\/?br\s*\/?>/i) : [];
                return (
                    <div className="d-grid gap-2">
                        <button className="btn btn-info fs-4 text-light" type="button" onClick={() => handleHubungi(row.original.perkara_id, hakims[0])}>
                            Hubungi Majelis <i className="fa fa-square-phone"></i>
                        </button>
                    </div>
                );
            },
            enableGlobalFilter: false,
            enableHiding: true,
        }),
    ];

    const statJenis = () =>{
        if(userIn && userIn.jenis != null){
            if(userIn.jenis.includes('Panitera') || 
            userIn.jenis.includes('Panmud') || 
            userIn.jenis.includes('Panitera Pengganti') || 
            userIn.jenis.includes('Administrator')){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    const columVis = {
        'status': false,
        'no': true,
        'nomor_perkara': true,
        'agenda': true,
        'pihak1': true,
        'pihak2': true,
        'hakim': true,
        'aksi': true,
        'aksi2': statJenis(),
    };

    //fungsi liat nama full

    const handlePihakClick = (pihak, buttonColor) => {
        let title = '';
        let icon = '';
        if (buttonColor === 'btn-danger') {
            title = 'Belum Hadir';
            icon = 'question';
        }else{
            title = 'Sudah Hadir';
            icon = 'success';
        }

        Swal.fire({
            title: `${title}`,
            text: `Kehadiran atas nama : ${pihak}`,
            icon: `${icon}`,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: "#0d6efd",
            cancelButtonText: 'Keluar'
        });
    };

    //fungsi panggil

    const handlePanggil = (id, perkara_id, nomor_perkara) =>{
        //let result = await ambil_data(`SELECT*FROM ref_ruang_sidang`);
        //let ruangsidang_input;
        Swal.fire({
            title: `Konfirmasi Panggilan`,
            html: `
                <p class='p-0 mb-2 fs-5 text-black'>Panggil perkara nomor : <b>${nomor_perkara}</b></p>
            `,
            icon: `question`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Panggil`,
            cancelButtonText: 'Batal',
            preConfirm: () => {
                updateStatus(id, perkara_id, nomor_perkara, 'panggil');
                loadingPanggil('Nomor Perkara ' + nomor_perkara, 'Sedang dipanggil untuk sidang');
                Panggil(perkara_id);
            }
        });
    };

    const updateStatus = async(id, perkara_id, nomor_perkara, aksi) =>{
        try{
            let result = await ambil_data(`SELECT*FROM data_status WHERE id = ${id}`);
            if(result.length < 1){
                let tambah = await tambah_data('data_status', [{id: id, perkara_id: perkara_id, status: 1, ket: 'Mulai sidang'}]);
                if(tambah.success){
                    sendUpdate();
                    fetch_data();
                }else{
                    console.log('Error tambah:', tambah.message);
                    alertError(tambah.message);
                }
            }else{
                if(result[0].status === '1'){
                    if(aksi === 'selesai'){
                        Swal.fire({
                            title: 'Selesai',
                            text: 'Apakah sidang sudah selesai ?',
                            showCancelButton: false,
                            showDenyButton: true,
                            confirmButtonText: 'Selesai',
                            denyButtonText: 'Belum',
                            confirmButtonColor: '#198754'
                            }).then(async(result) => {
                            if (result.isConfirmed) {
                                let edit = await edit_data('data_status', id, [{ status: 2, ket: 'Selesai sidang'}]);
                                if(edit.success){
                                    alertNotif('Selesai', `Sidang perkara nomor ${nomor_perkara} sudah selesai`, 'success');
                                    sendUpdate();
                                    fetch_data();
                                }else{
                                    console.log('Error tambah:', edit.message);
                                    alertError(edit.message);
                                }
                            }
                        });
                    }
                }
            }
        }catch(error){
            console.error('Error update status: ', error);
            alertError(error);
        }
    };

    const sendUpdate = () =>{
		if (ws.current.readyState === WebSocket.OPEN) {
			ws.current.send(JSON.stringify({ type: 'update_status' }));
		} else {
			console.error('Error gagal kirim update status');
            alertError('Error gagal kirim update status');
		}
    };

    const loadingPanggil = (title, text) => {
		Swal.fire({
			icon: 'info',
			title: title,
			text: text,
			allowOutsideClick: true,
			showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: `Selesai`,
            footer: '<p class=p-0 m-0 fs-8>Harap tunggu, proses sedang berjalan. Pop-up ini akan menutup secara otomatis setelah proses selesai.</p>',
			didOpen: () => {
				Swal.showLoading();
			},
		});
	};

    const Panggil = (id, ruangsidang) => {
		if (ws.current.readyState === WebSocket.OPEN) {
			ws.current.send(JSON.stringify({ type: 'panggil', perkara_id: id, ruang_sidang: ruangsidang, userid: userIn.userid }));
		} else {
			console.error('Error gagal kirim status panggil');
            alertError('Error gagal kirim status panggil');
		}
	};

    //fungsi hubungi majelis

    const handleHubungi = async(perkaraId, hakimNama) =>{
        //console.log(hakimNama);
        let hakim = hakimNama.match(/:\s*(.+)$/);
        let h = hakim[1].split(/','|,/i);
        let query = `
            SELECT
                vdp.perkara_id,
                vdp.nomor_perkara,
                vdp.agenda,
                GROUP_CONCAT(
                    DISTINCT CASE
                        WHEN vpdh.status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                        ELSE NULL
                    END
                    SEPARATOR ' ,'
                ) AS pihak1,
                GROUP_CONCAT(
                    DISTINCT CASE
                        WHEN vpdh.status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') AND dk.kehadiran = 'Y' THEN vpdh.nama
                        ELSE NULL
                    END
                    SEPARATOR ' ,'
                ) AS pihak2,
                vdp.majelis_hakim_text AS hakim,
                vdp.panitera_pengganti_text AS panitera_pengganti,
                ref.nomor_hp
            FROM
                view_data_perkara vdp
            LEFT JOIN
                ref_no_hp ref ON ref.userid = (
                                                SELECT userid 
                                                FROM view_data_user vds 
                                                WHERE vds.fullname LIKE '%${h[0]}%'
                                               )
            LEFT JOIN
                view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
            LEFT JOIN
                data_kehadiran dk ON vdp.perkara_id = dk.perkara_id AND vpdh.nama LIKE CONCAT('%', dk.nama, '%') AND dk.tglSidang = '${formattedDate(today)}'
            WHERE
                vdp.perkara_id = '${perkaraId}'
                AND vdp.tglSidang = '${formattedDate(today)}'
            GROUP BY
                vdp.perkara_id;    
        `;
        try{
			const result = await ambil_data(query);
			hubungi(result[0]);
            //console.log(h[0]);
		} catch(error){
			console.error('Error fetching', error);
            alertError(error);
		}
    };

    const hubungi = (data) =>{
        console.log(data.nomor_hp);
        let nomor_perkara = data.nomor_perkara;
        let agenda = data.agenda;
        let pihak1 = data.pihak1;

        if(pihak1 === null){
            pihak1 = 'Belum ada yg hadir'
        }

        let pihak2 = data.pihak2;

        if(pihak2 === null){
            pihak2 = 'Belum ada yg hadir'
        }

        let hakim = data.hakim.split(/<\/?br\s*\/?>/i) || [];
        let formattedHakim = [];

        hakim.forEach(part => {
            let nameMatch = part.match(/:\s*(.+)$/);
            if (nameMatch) {
                let formattedName = nameMatch[1].replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                formattedHakim.push(formattedName.replace(/,\s*$/, '')); 
            }
        });

        hakim = formattedHakim.join(', ');

        let pp = data.panitera_pengganti.split(':').pop().trim().replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        let nomor = data.nomor_hp;
        let pesan = `Salam Mendoan, ingin menginformasikan..\n\nPerkara Nomor : ${nomor_perkara}\nAgenda : ${agenda}\nKehadiran Pihak 1 : ${pihak1}\nKehadiran Pihak 2 : ${pihak2}\nHakim : ${hakim}\nPanitera Pengganti : ${pp}\n\nTerimakasih ..`;
        let link = `https://wa.me/${nomor}?text=${encodeURIComponent(pesan)}`;
        window.location.href = link;
        //console.log(link);
    };

    //fungsi ganti page

    const dataTampil = (page) =>{
        switch(page){
            case 0:
                setData(data0);
            break;
            case 1:
                setData(data1);
            break;
            case 2:
                setData(data2);
            break;
            default:
                setData(data0);
        }
    };

    const handlePage = (nextPage) => {
        setPrevPage(pageStat);
        setPage(nextPage);
    };

    useEffect(() => {
        dataTampil(pageStat);
        if (pageStat !== prevPage) {
            const prevPageButton = document.querySelector(`.nav-link.active`);
            if (prevPageButton) {
                prevPageButton.classList.remove('active');
            }
        }
        // eslint-disable-next-line
    }, [pageStat, prevPage]);

    useEffect(()=>{
        dataTampil(pageStat);
        // eslint-disable-next-line
    }, [data0, data1, data2]);

    //fungsi tombol selesai

    const logout = () =>{
        if(loggedIn){
            setLoggedin(false);
        }
        setData([]);
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('userIn');
        localStorage.removeItem('loginTime');
        if(ws.current !== null){
            ws.current.close();
        }
        ws.current = null;
        popUpLogin();
    };

    useEffect(()=>{
        if(statWS !== null){
            cekWS(statWS);
        }
        // eslint-disable-next-line
    }, [statWS]);
    
    return(
        <div>
			<div className="limiter">
				<div className="background-container">
					<div className="wrap">
                        <div className='container'>
                            <div className='row'>
								<div className='col'>
                                    <div className='row justify-content-end'>
                                        <div className='col'>
                                            <p className='p-0 m-0 fw-bold fs-2 text-black text-end'>{userIn.fullname}</p>
                                            <p className='p-0 m-0 fs-6 text-black text-end'>Tanggal {formattedHariIni(today)}</p>    
                                            <p className='p-0 m-0 fs-5 text-black text-end'>Anda memiliki <b className='badge rounded-pill text-bg-success'>{data0.length + data1.length + data2.length }</b> agenda sidang hari ini</p>   
                                        </div>
                                    </div>
                                    <div className='row text-end mt-3'>
                                        <div className='col'>
                                            <p className='p-0 m-0 fs-8 text-black'><b>*</b>Status Kehadiran Pihak : <span className='badge text-bg-danger text-wrap'>Merah</span> Belum Hadir | <span className='badge text-bg-success text-wrap'>Hijau</span> Sudah Hadir</p>
                                        </div>
                                    </div>
                                    <div className='row flex-column'>
                                        <div className='col'>
                                            <ul className="mt-3 mb-2 nav nav-tabs nav-fill justify-content-center fs-25 position-sticky top-0">
                                                <li className="nav-item">
                                                    <button onClick={() => { handlePage(2)}} className={`nav-link position-relative bg-white ${pageStat === 2 ? 'active' : ''}`}>
                                                        Selesai
                                                        <span className='position-absolute top-0 start-50 translate-middle badge rounded-pill text-bg-success'>
                                                            {data2.length}
                                                        </span>
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button onClick={() => { handlePage(1)}} className={`nav-link position-relative bg-white ${pageStat === 1 ? 'active' : ''}`}>
                                                        Mulai
                                                        <span className='position-absolute top-0 start-50 translate-middle badge rounded-pill text-bg-primary'>
                                                            {data1.length}
                                                        </span>
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button onClick={() => { handlePage(0)}} className={`nav-link position-relative bg-white ${pageStat === 0 ? 'active' : ''}`}>
                                                        Tunggu
                                                        <span className='position-absolute top-0 start-50 translate-middle badge rounded-pill text-bg-danger'>
                                                            {data0.length}
                                                        </span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col'>
                                            <Table columns={columns} data={data} columnVis={columVis} statVis={pageStat} />
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button className='btn btn-warning' onClick={logout}>Logout</button>
                                        { /* <button className='btn btn-warning' onClick={() => alertQuestion('Coba question', 'coba hihi', handleTrue, null)}>cek</button> */ }
                                    </div>
								</div>
							</div>
                            <div className='row mb-3'>								
								<div className='col text-end'>
									<p className='copy_right p-0 m-0 text-dark'>Copyright @sijenggo86</p>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;